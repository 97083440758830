import { ethers } from "ethers";
import dayjs from "dayjs";
import { ThemeMode } from "@spheron/ui-library";
import { NetworkType, IToken, tokenMap } from "@/config";

export const isValidEthereumAddress = (address: string): boolean =>
  ethers.isAddress(address);


export const formatAddress = (address: string): string => {
  if (!address.startsWith('0x')) {
    return '0x' + address;
  }
  return address;
}

export const truncateEnd = (text: string, truncateLength: number = 5) => {
  if (text.length > 12) {
    return `${text.slice(0, text.length - truncateLength)}....`;
  }
  return text;
};

export const truncateAddress = (
  address: string,
  substringLength: number = 4
): string =>
  address.length <= 8
    ? address
    : `${address.substring(0, substringLength)}...${address.substring(
      address.length - substringLength
    )}`;

export const setGlobalTheme = (theme: ThemeMode) => {
  const root = window.document.documentElement;
  root.classList.remove(String(ThemeMode.DARK));
  root.classList.remove(String(ThemeMode.LIGHT));
  root.classList.add(theme);
  root.setAttribute("data-theme", theme);
};

export const getTokenDetails = (
  tokenAddress: string,
  networkType: NetworkType
): IToken | undefined => {
  return (
    tokenMap[networkType]?.find(
      (token) => token.address.toLowerCase() === tokenAddress.toLowerCase()
    ) || tokenMap[networkType][0]
  );
};

export const getProviderStatusFromStatusId = (statusId: number) => {
  switch (statusId) {
    case 1:
      return "active";
    case 2:
    default:
      return "terminated";
  }
};

export const copyToClipboard = async (copyText: string) => {
  if (typeof window !== "undefined") {
    await navigator.clipboard.writeText(copyText);
  }
};

export const convertEarningsToDisplay = (earning: string | number) => {
  const divisor = 10 ** 18;
  return Number(earning) / divisor;
};

export const mapTokenToId = (token: string) => {
  const tokens = new Map<string, number>();
  tokens.set("USDT", 825);
  tokens.set("WMATIC", 3890);
  tokens.set("WETH", 2396);
  tokens.set("DAI", 4943);
  tokens.set("USDC", 3408);
  tokens.set("AKT", 7431);
  tokens.set("MATIC", 3890);
  tokens.set("SOL", 5426);
  tokens.set("WAVAX", 5805);
  tokens.set("WBNB", 1839);
  tokens.set("tFIL", 2280);
  tokens.set("FIL", 2280);
  tokens.set("USDbC", 3408);
  tokens.set("WMNT", 27614);
  tokens.set("WXDAI", 9021);
  return tokens.get(token);
};

export const GpuTiers: {
  [x: string]: {
    multiplier: number;
    tier: number;
  };
} = {
  "Entry 1": {
    multiplier: 0.1,
    tier: 10,
  },
  "Entry 2": {
    multiplier: 0.25,
    tier: 9,
  },
  "Low 1": {
    multiplier: 0.5,
    tier: 8,
  },
  "Low 2": {
    multiplier: 0.75,
    tier: 7,
  },
  "Medium 1": {
    multiplier: 1,
    tier: 6,
  },
  "Medium 2": {
    multiplier: 1.5,
    tier: 5,
  },
  "High 1": {
    multiplier: 2,
    tier: 4,
  },
  "High 2": {
    multiplier: 3,
    tier: 3,
  },
  "Ultra High 1": {
    multiplier: 6,
    tier: 2,
  },
  "Ultra High 2": {
    multiplier: 12,
    tier: 1,
  },
};

export const TrustTierMultiplier: { [x: number]: number } = {
  1: 20,
  2: 17,
  3: 15,
  4: 12,
  5: 11,
  6: 10,
  7: 0,
};

export const getTierHoverText = (tier: string, multiplier: number): string => {
  return `🙌 You've earned tier ${tier} with ${multiplier}x multiplier`;
};

export const getUSDAmount = (
  livePrice: number | null,
  amount: number
): string => {
  if (livePrice) {
    return `${(livePrice * Number(amount)).toFixed(2)}`;
  }
  return "";
};

export const calculateDaysBetweenTimestamps = (
  timestamp1: string | number, timestamp2: string | number
): number => {
  const date1 = dayjs(timestamp1);
  const date2 = dayjs(timestamp2);
  const daysDifference = Math.floor(date2.diff(date1, 'day', true));
  return daysDifference;
};
