import USDTIcon from "@spheron/ui-library/dist/assets/crypto-social/Tether.svg?url";
import { USDT } from "@/contracts/addresses";

export const SPHERON_RPC_URL = "https://spheron-devnet-eth.rpc.caldera.xyz/http";
// export const SPHERON_RPC_URL = "https://sepolia-rollup.arbitrum.io/rpc";
export const SAMPLE_PROVIDER_ADDRESS =
  "0x6634d41cccBD1E1576Ed4c6226832521A66bF874"; // us-east provider
// export const SAMPLE_PROVIDER_ADDRESS =
//   "0x562937835cdD5C92F54B94Df658Fd3b50A68ecD5";
// export const SAMPLE_PROVIDER_ADDRESS =
//   "0x74bb7e5058Fa6FCE9928FAC9A285377E5dFD1680";
export const SPHERON_DEVNET_EXPLORER_URL =
  "https://spheron-devnet-eth.explorer.caldera.xyz/";
export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://provider.spheron.network"
    : "http://localhost:3010";
export const SERVICE_API_URL = "https://api-v2.spheron.network";

export interface IToken {
  id: number;
  name: string;
  symbol: string;
  decimal: number;
  address: string;
  logo: any;
}

export type NetworkType = "testnet" | "mainnet";

export const tokenMap: Record<NetworkType, IToken[]> = {
  testnet: [
    {
      id: 1,
      name: "Tether USDT",
      symbol: "USDT",
      decimal: 6,
      address: USDT,
      logo: USDTIcon,
    },
  ],
  mainnet: [],
};

export const networkType =
  (process.env.NEXT_PUBLIC_NETWORK_TYPE as string) || "testnet";
