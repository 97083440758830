"use client";

import { useEffect, useState } from "react";
import { Button, Dropdown, Feedback, OptionType, TextInput } from "@spheron/ui-library";
import Send from "@spheron/ui-library/dist/assets/send.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEthereumAddress } from "@/utils";
import { NotificationType, showToastNotification } from "@/utils/notification";

export default function Faucet() {
  const [token, setToken] = useState<string | undefined>();
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");
  const [errorText, setErrorText] = useState<string>();
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (walletAddress.length > 0) {
      if (!isValidEthereumAddress(walletAddress)) {
        setErrorText("Enter valid ethereum address");
      } else {
        setErrorText("");
      }
    } else {
      setErrorText("");
    }
  }, [walletAddress]);

  const tokensOptions = [
    {
      label: "ETH",
      value: "ETH",
      optionType: "primary" as OptionType,
    },
    {
      label: "USDT",
      value: "USDT",
      optionType: "primary" as OptionType,
    },
    {
      label: "USDC",
      value: "USDC",
      optionType: "primary" as OptionType,
    },
    {
      label: "WETH",
      value: "WETH",
      optionType: "primary" as OptionType,
    },
    {
      label: "DAI",
      value: "DAI",
      optionType: "primary" as OptionType,
    },
  ];

  useEffect(() => {
    setToken(tokensOptions[0].value);
  }, []);

  const handleSendTokens = async () => {
    if (!recaptchaToken) {
      setMessage("Please complete the reCAPTCHA");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch("/api/request-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ address: walletAddress, token, recaptchaToken }),
      });
      const data = await response.json();
      setMessage(data.message || data.error);
      if (!data.error) {
        showToastNotification({
          message: data.message,
          timestamp: Date.now(),
          type: NotificationType.Success,
          title: "Success",
        });
      } else {
        showToastNotification({
          message: data.error,
          timestamp: Date.now(),
          type: NotificationType.Error,
          title: "Error",
        });
      }
    } catch (error) {
      setMessage("Error occurred");
      showToastNotification({
        message: "Error occured",
        timestamp: Date.now(),
        type: NotificationType.Error,
        title: "Error",
      });
    }
    setLoading(false);
  };
  return (
    <div className="flex flex-col gap-y-2 justify-center items-center mt-20 w-[360px] mx-auto">
      <h1 className="text-2xl font-semibold text-base-heading-text-color dark:text-dark-base-heading-text-color">
        Spheron Faucet
      </h1>
      <div className="w-full">
        <Dropdown
          // leftIcon={
          //   <TokenIcon className="h-6 w-6" />
          // }
          dropdownType="default"
          dropdownSize="default"
          filled
          options={tokensOptions}
          defaultSelected={tokensOptions[0].value}
          placeholder={tokensOptions[0].label}
          onSelected={(selected) => setToken(selected.value)}
          dropdownClassname="!w-[360px]"
        />
      </div>
      <div className="w-full">
        <TextInput
          inputSize="default"
          //         leftIcon={
          //           <SearchIcon
          //             className="w-6 h-6 text-base-para-text-color
          // dark:text-dark-base-para-text-color"
          //           />
          //         }
          placeholder="Enter your wallet address (0x...)"
          value={walletAddress}
          onChange={(value) => {
            setWalletAddress(value as string);
          }}
          error={!!errorText}
          helpText={errorText}
          // onKeyDown={(e: any) => {
          // }}
        />
      </div>
      <div className="self-start mt-8">
        <ReCAPTCHA
          sitekey="6LcdIhEqAAAAAA4xORu-3YbjHQw5MLsZFLgZn4PH"
          onChange={(tokenVal) => setRecaptchaToken(tokenVal as string)}
          className="mb-2"
        />
      </div>
      <div className="w-full">
        <Button
          buttonType="primary"
          size="large"
          label="Send Tokens"
          fillWidth
          disabled={!!errorText || walletAddress === "" || recaptchaToken === ""}
          rightIcon={<Send className="w-5 h-5" />}
          onClick={async () => {
            await handleSendTokens();
          }}
          loading={loading}
        />
      </div>
      {/* <div>
        <Feedback feedbackType={"warning"} title={message} />
      </div> */}
    </div>
  );
}
